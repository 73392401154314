.add-route-auto-complete-main-cotaniner {
  font-family: IBM Plex Sans;

  width: 640px;
  /* height: 600px; */
  /* border-radius: 8px; */
  border-width: 0px 8px 8px 8px;
  border-color: #5c7670;
  border-style: solid;
}
.add-route-auto-complete-top-cotaniner {
  width: 640px;
  /* height: 76px; */
  padding: 24px 24px 20px 24px;
  gap: 16px;
  /* opacity: 0px; */
  background-color: #5c7670;
  box-sizing: border-box;
  display: flex;
}
.add-route-auto-complete-top-cotaniner svg{
  cursor: pointer;
}
.add-route-auto-complete-busicon-container-invalid,
.add-route-auto-complete-busicon-container {
  display: flex;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid #98a8a4;

}
.add-route-auto-complete-busicon-container {
  background-color: #445f59;
}
/* .add-route-auto-complete-busicon-container s */
.add-route-auto-complete-header-container {

  width: 100%;
  box-sizing: border-box;
  color: white;
}
.add-route-auto-complete-description,
.add-route-auto-complete-header {
  line-height: 28px;
  text-align: left;
}
.add-route-auto-complete-header {

  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.add-route-auto-complete-description {
  font-size: 14px;
  font-weight: 400;
}
.add-route-auto-complete-content-container {
  display: flex;
  padding: 8px 24px 8px 24px;
  gap: 20px;
  flex-direction: column;
  background-color: white;
}
.add-route-auto-complete-content-container .custom-input-main-container {
  display: flex;
  gap: 6px;
  flex-direction: column;
}
.add-route-auto-complete-content-container textarea {
  height: 118px;
  resize: none;
  width: 100%;
  color: #344054;
  font-weight: 400;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  box-sizing: border-box;
  
}
.add-route-auto-complete-content-container textarea::-webkit-scrollbar{

  width: 5px;

}
.add-route-auto-complete-content-container textarea::-webkit-scrollbar-thumb {
  background: #5C7670; 
}
.add-route-auto-complete-content-container textarea::-webkit-scrollbar-thumb:hover {
  background: #3e524d;
  cursor: pointer;
}
.add-route-auto-complete-content-container textarea::-webkit-scrollbar-track {
  background: #d3d2d2;
}


.add-route-auto-complete-content-container textarea::placeholder,
.add-route-auto-complete-content-container input::placeholder {
  color: #d0d5dd;
}
.add-route-auto-complete-bottom-cotaniner {
  padding: 0px 0px 10px 0px;
  display: flex;
  gap: 16px;

}
.add-route-auto-complete-bottom-cotaniner button{
  align-items: center;
  justify-content: center;
  flex: 1;
}
.add-route-auto-complete-autocomplete-fields {
  display: flex;
  gap: 16px;
}
.add-route-auto-complete-autocomplete-fields input {
  box-sizing: border-box;
  width: 100%;
}
.add-route-auto-complete-header-container p {
  padding: 0;
  margin: 0;
}
.arac-reset-button {
  background: none;
  border: 1px solid #d0d5dd;
  color: #344054;
  cursor: pointer;
}
.arac-save-button {
  border: 1px solid #83111d;
  background-color: #83111d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

}
.arac-save-button:disabled {
  background-color: #cccccc; 
  color: #83111d;
  border-color: #83111d;
  cursor: not-allowed;
}
.arac-save-button:enabled:hover {
  background-color: #58040d;
  cursor: pointer;
}