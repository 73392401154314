.add-custom-location-main-container {
  display: flex;
  justify-content: space-between;
  border: 8px solid #5c7670;
  border-top: 0px;
  padding-bottom: 0;
}
.add-custom-form-buuton-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 24px;
  padding-top: 16px;
  justify-content: space-between;
  border-right: 3px solid #98a8a4;
  position: relative;
  background-color: white;
  flex-wrap: wrap;
}
.icon-icon-icon {
  position: absolute;
  justify-self: end;
}
.add-custom-location-fields-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
}
.add-custom-location-fields-container input {
  flex: 0 !important;
}
.add-custom-locations-button-container {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.add-custom-location-text-field {
  background-color: #f9fafb;
  margin-top: 1vh;
  height: 56px;

  padding: 1vh;
  border: 1px solid #eaecf0;
  display: flex;
  justify-content: space-between;
}
.add-custom-location-map-cotaniner {
  height: 70vh;
}
.add-custom-location-map-cotaniner .google-map-container {
  width: 100%;
  margin: 0px;
}
.add-custom-location-header-container {
  display: flex;
  justify-content: space-between;
}
.add-custom-location-header-container h2 {
  margin: 0;
  padding: 0;
}

.custom-location-top-container {
  background-color: #5c7670;
  padding: 16px 24px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.custom-location-header-container {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: white;
}
