.outlined-button-style {
  background: transparent;
  color: #883C40 !important;
  /* border: 0.5px solid #83111d !important; */
  text-transform: none !important;
}
.contained-button-style {
  background-color: #883C40 !important;
  border: 1px solid #270104 !important;
  text-transform: none !important;
  color: white !important;
}
.contained-button-style:hover {
  background-color: #46090f !important;
}
.filled-green-custom-button,
.invalid-custom-button,
.white-custom-button,
.filled-custom-button,
.contained-yellow-button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  gap: 8px;
  border: none;
  border-radius: 4px;
  align-items: center;
  /* height: 40px; */

  padding: 9px 16px 9px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #1018280D;

}

.invalid-custom-button,
.white-custom-button,
.filled-custom-button
{
  border: 1px solid #883c40;

}
.filled-green-custom-button{
  background-color: #0B382A;

  color: white;
  /* padding-top: 0.5rem; */

}
.filled-custom-button {
  color: white;
  background-color: #883c40;
}
.filled-custom-button:hover {
  background-color: #521a1d;
  box-shadow:0px 2px 2px 0px #883c401a;
}
/* .filled-custom-button :hover{
    color: black;

} */
.white-custom-button {
  color: #883c40;
  background-color: white;
  border-collapse: collapse;
}
.white-custom-button:hover{
    background-color: rgb(245, 245, 245);
}

.invalid-custom-button {
  color: gray;
  background-color: #f0f0f0;
  border: 1px solid gray;
  cursor: default;
}
.invalid-custom-button svg path{
  stroke: gray;
}

.button-view {
  border: 1px solid #883c40 !important;
  width: 82px;
  height: 40px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px, 16px, 10px, 16px !important;
  background-color: #883c40 !important;
  color: white !important;
  text-transform: none !important;
}
