.thankYouContentMainContainer{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    /* background-color: rebeccapurple; */
    top: 0;
    /* padding-top: 5vh; */
    box-sizing: border-box;
    flex-direction: column;
    gap: 10vh;

}
.invalidPngContainer,.pngContainer{
    width: 15vh;
    height:  15vh;
    background-size: cover;
}
.pngContainer{
    background-image: url('../../../public/assets/correctPNG.png');
}
.invalidPngContainer{
    background-image: url('../../../public/assets/delete-button.png');

}
.thankYou-text-container{
    font-family: IBM Plex Sans;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2vh;
    font-size: 1.3rem;
}

.button-download {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 150px;
}