.predefined-time-dialog-main-container {
  border: 8px solid #5c7670;
  border-top: 0px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 8px;
}
.predefined-times-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.predefined-times-main-container,
.predefined-times-message-container {
  gap: 10px;
  min-width: 285px;

  padding: 10px;
  background-color: rgb(248, 248, 248);
}
.predefined-times-main-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: 200px;
  /* min-height: 200px; */
  min-width: 285px;
  overflow-y: auto;
  padding: 10px;
}

.predefined-times-main-container::-webkit-scrollbar {
  width: 5px;
}
.predefined-times-main-container::-webkit-scrollbar-thumb {
  background: #5c7670;
}
.predefined-times-main-container::-webkit-scrollbar-thumb:hover {
  background: #3e524d;
  cursor: pointer;
}
.predefined-times-main-container::-webkit-scrollbar-track {
  background: #d3d2d2;
}
.predefined-time-badge-container,
.unsaved-predefined-time-badge-container {
  max-height: 32px;
  border-radius: 8px;
  padding: 3px 5px 3px 5px;
  box-sizing: border-box;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}
.unsaved-predefined-time-badge-container {
  color: rgb(189, 121, 0);
  background-color: rgb(255, 244, 225);
  border: 1px solid rgb(189, 121, 0);
}
.predefined-time-badge-container {
  background-color: #ecfdf3;
  color: #027a48;
  border: 1px solid #027a48;
}
.predefined-times-buutons-cotainer button {
  width: 100%;
}
.predefined-times-buutons-cotainer {
  display: flex;
  gap: 10px;
}
.predefined-times-menu-main-container {
  z-index: 5;
  position: absolute;
  /* background-color: rebeccapurple; */
  width: 100%;
  background-color: white;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}
.predefined-times-menu-item-main-container,
.predefined-times-menu-item-main-container-selected{
  margin: 2px;
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
}
.predefined-times-menu-item-main-container-selected {
  background-color: #1976d2;
  color: #fff;
}
.predefined-times-menu-item-main-container
{
  border-bottom: 1px solid rgb(219, 219, 219);
}
.predefined-times-menu-item-main-container:hover{
  background-color:rgb(199, 199, 199);
  cursor: pointer;
}
