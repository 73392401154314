
.paginator-content-container{
  padding: 16px;
  border-top: 1px solid ;
  border-color: #EAECF0;
background-color: white;
  /* border: ; */
}
/* General button styles */
.pagination-item button {
  font-family: "IBM Plex Sans", sans-serif;
  color: #1D2939;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500;
  margin-left: 2px;
  height: 40px;
  width: 40px;
  border: 0px;
  background-color: transparent;
}

/* Apply styles for the first and last buttons */
.pagination-item.first-button {
  margin-right: auto; /* Pushes the remaining items to the right */
}

.pagination-item.last-button {
  margin-left: auto; /* Pushes the remaining items to the left */
}

/* Center all buttons except the first and last */
.pagination-item.page-button {
  /* margin: 0 4px; */
}

/* Additional styling for selected page button */
.pagination-item button[aria-current="true"] {
  background-color: #f0f0f0;
  /* color: white; */
  border: none;
  font-weight: bold;
}

/* Optionally add hover effects */
.pagination-item button:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.next-button,
.previouse-button {
  height: auto !important;;
  width: auto !important;
  color: #344054 !important;
  border-radius: 4px !important;
  border: 1px solid #d0d5dd !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px !important;
  padding: 8px 14px 8px 14px !important;
  margin:0px !important;
  font-weight: 600 !important;
  /* font-size: 14px !important;
    line-height: 20px !important; */
}

.previouse-button:disabled ,
.next-button:disabled {
  /* Styles for disabled button */
  background-color: #dddddd ; /* Example disabled background color */
  color: #6c757d; /* Example disabled text color */
  /* cursor: not-allowed; */
}
.previouse-button:disabled:hover ,
.next-button:disabled:hover{
  cursor:auto;
  background-color: #dddddd ;

}