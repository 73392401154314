.show-routes-main-container {
  /* margin: 2vh; */

  /* height: calc(100vh - 60px); */

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
  /* margin-left: 2%; */
}
.show-routes-component-paginator-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.3vh;

  /* margin-top: 1vh; */
}
.show-routes-table-container {
  border: 5px solid #98a8a4;
  box-shadow: 0px 1px 3px 0px #1018281a;
  border-radius: 8px;
  /* box-shadow: 0px 1px 2px 0px #1018280F; */
}
.show-routes-table-container {
  margin-bottom: 0;
}
.show-routes-button-header-container {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.routes-component-header {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #344054;
}
.show-route-button {
  background: transparent;
  color: #83111d !important;
  border: 1px solid #83111d !important;
}
.routes-component-content-container {
  width: 1280px;
  /* padding-top: 10px; */
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 100%;
  box-sizing: border-box;
}
.routes-component-noroutesfound-message{
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  color: #344054;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

}