.menu-settings {
  display: flex;
  flex-direction: column;
}

/* .heder-container{
    max-width: 1280px;
}
.header-main-container header{
    box-shadow: none !important;
} */

.header-link-container,
.header-lenguage-span {
  font-family: 'Poppins', sans-serif;

  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.header-lenguage-span {
  color: #883c40;
  /* width: 31px; */
}

.header-link-container {
  height: 72px;
  display: flex;
  align-items: center;
  color: #344054;
  box-sizing: border-box;
  border-bottom: 5px solid transparent;
  padding: 12px;
  display: flex;
  gap: 8px;
  text-decoration: none;
}

.header-link-container:hover {
  /* border-bottom: 5px solid #883C40; */
  color: #883c40;
  border-color: #883c40;
  background-color: rgb(252, 252, 252);
}

.header-link-container.active {
  border-color: #883c40;
}

/* .active-link{
} */
.header-icon-containe,
.header-icon-container {
  height: 40px;
  aspect-ratio: 1/1;
  /* background-color: red; */
  display: block;
  background-image: url('../../../public/assets/terra-logo-round.png');
  background-size: cover;
  background-position: center;
  /* filter: blur(2px); */
  background-repeat: no-repeat;
}

.menu-link-container {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #344054;
  text-decoration: none;
  padding: 9px 10px 9px 10px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  min-width: 200px;
}

.menu-link-container svg {
  width: 16px;
  height: 16px;
}

.menu-link-container path {
  stroke: #344054;
}

.menu-link-container.active {
  /* background-color: rgb(236, 236, 236); */
  color: #883c40;
}

.header-menu-container {
  position: relative;
}

.header-menu-user-info-container {
  padding: 9px 10px 9px 10px;

  display: flex;
  flex-direction: column;
  align-items: start;
}

.header-menu-container li {
  padding: 0px !important;
  border: 1px solid #f9fafb;
}

.menu-itam-user-info:hover {
  background-color: transparent !important;
}

.menu-itam-user-info.selected {
  background-color: transparent !important;
}

.header-name-user-style {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #883c40;
}

.header-mail-user-style {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
}

@media (max-width: 900px) {
  .header-icon-container {
    display: none;
  }
}

.settings {
  width: 20px;
  height: 20px;
  padding: 10px;
  gap: 8px;
  border-radius: 200px;
  background-color: #F9FAFB;
}
.setting-open-drive{
  color: #883c40;
  /* font-size: 12px; */
}

.app-bar-notification-number {
  align-items: center;
    aspect-ratio: 1 / 1;
    background-color: rgba(255, 0, 0, .959);
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 600;
    justify-content: center;
    min-height: 15px;
    min-width: 18px;
    /* padding: 4px; */
    position: absolute;
    text-align: center;
    transform: translate(90%, -1px);
    /* white-space: nowrap;
    width: auto; */
}

.drawer-content-no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
}

/* .sms-notification-drawer.MuiDrawer-root.MuiDrawer-modal.sms-notification-drawer.MuiModal-root.css-1u2w381-MuiModal-root-MuiDrawer-root{
  z-index: 100;
} */

.helper-icon-position{
  /* position: absolute;
  left: 96%;
  transform: translate(100%, 100%); */
  color: #b1b0b0;
  cursor: pointer;
}