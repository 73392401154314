.add-payment-method{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 10px;
}

.add-payment-method-button {

    padding: 10px 10px;
    font-size: 16px;
    background-color:#883C40 !important;
    margin-right: 30px !important;
    text-transform: none !important; 
    font-family: IBM Plex Sans !important;    

}

.card {
    padding: 10px;
}

.payment-title-card 
{
    font-family: Poppins !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    gap: 0px;
    /* opacity: 0px; */
    color: #344054 !important;
}

.payament-methode-table {
    
    border: 5px solid #98A8A4;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 50px;
    width: 1280px;
    height: 525px;
    display: flex;
    flex-direction: column;

}
.table-subagent .table-container {
    flex: 1;
}

.pagination-bottom {
    margin-top: auto;
}

.payment-header {
    width: 1280px;
    display: flex;
    justify-content: space-between;
}
.payment-methode-main-cotainer{
    /* width: 100%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}