.show-all-users-main-container {
  /* height: 100%; */
  /* height: calc(100vh - 12vh); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1280px;
  max-width: 1280px;
  gap: 32px;
  /* align-items: center; */
}

.show-all-users-body-container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-all-users-table-container {
  /* box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2); */
  /* border: 1px solid #c5e1a5; */
  width: 100%;
}
.show-all-users-filter-container {
  /* box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2); */
  background-color: rgb(240, 240, 240);
  display: flex;
  /* width: 100%; */
  /* border: 1px solid rgba(125,25,32,255); */
  /* background-color: #dcedc8; */
  gap: 10px;
  justify-content: space-between;
  /* margin-bottom: 1%; */
  padding: 1vh;
}
.show-all-users-dropdown {
  width: 200px;
  margin-left: 1vh;
}
.show-all-users-compponent-filter-button:hover {
  background-color: #fff;
  color: rgba(125, 25, 32, 255);
}
.show-users-component-paginator-container {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
.empty-div-users-component {
  flex: 4;
}
.users-dropdowns-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 15px;
}

.user-teble-actions-cell svg {
  font-size: 24px !important;
  color: #919dad;
}
.user-teble-actions-cell path {
  font-size: 24px !important;
}

.edit-icon-color-change path{
  stroke: #919dad;

}
@media (max-width: 1200px) {
  /* Adjust the value to your specific needs */
  .empty-div-users-component {
    flex: 2;
  }
}

/* Media query to make the element disappear */
@media (max-width: 800px) {
  /* Adjust the value to your specific needs */
  .empty-div-users-component {
    display: none;
  }
  .show-all-users-filter-container {
    flex-wrap: wrap;
  }
  .users-dropdowns-container {
    flex-wrap: wrap;
  }
}
