.cards {
  width: auto;
  height: Hug (160px) px;
  gap: 32px;
  /* opacity: 0px; */
  display: flex;
  /* margin-bottom: 2vh; */
  width: 100%;
  flex-wrap: wrap;
}

.metric-item {
  position: relative;
  overflow: hidden;
  flex: 1;
  height: 160px;
  border-radius: 10px;
  border: 1px solid #eaecf0;
  background: #ffffff;
  box-shadow:
    0px 1px 0px 0px #1018281a,
    0px 1px 2px 0px #1018280f;
  padding: 0 16px;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 331px;
  height: 160px;
  opacity: 0.4;
  opacity: 0.4;
  border-radius: 0px 36px 36px 0px;
  z-index: 0;
}

.background-overlay .item-1::before,
.background-overlay .item-2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.item-1 {
  background: linear-gradient(45deg,
      rgba(133, 24, 30, 0.8) 60%,
      rgba(150, 83, 86, 0.8) 80%),
    url('../../../../public/assets/login-picture.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 80%;
}

.item-2 {
  background: linear-gradient(45deg,
      rgba(133, 24, 30, 0.8) 60%,
      rgba(62, 8, 8, 0.9) 80%),
    url('../../../../public/assets/login-picture.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 80%;
}

.item-3 {
  background: linear-gradient(45deg,
      rgba(11, 56, 42, 0.8) 60%,
      rgb(67, 108, 96) 90%),
    url('../../../../public/assets/login-picture.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 80%;
}

.text-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.all-reservations-cards-heading {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.all-reservations-info-uncaceled,
.all-reservations-info-canceled,
.all-reservations-info-all {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  text-align: right;
  display: flex;
  align-items: center;
}

.all-reservations-info-all {
  color: #e7a72c;
}

.all-reservations-info-canceled {
  color: #d92d20;
}

.all-reservations-info-uncaceled {
  color: #039855;
}

.paper-card {
  color: white;
  margin: 15px 0px 15px 0px;
  display: flex;
  justify-content: space-between;
  width: 30%;
  height: 92px;
  border: 1px solid gray;
  border-radius: 1px;
  padding: 24px;
  position: relative;
  background:
    linear-gradient(to right, rgba(0, 0, 0, 0.5) 80%, rgba(0, 0, 0, 0.5) 100%),
    url('../../../../public/assets/login-picture.png') no-repeat left center;
  /*background-size: cover;*/
  background-size: 70%;
  background-size: cover;
}

/* .paper-card::before {
    border-radius: 0 25px 25px 0;

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
} */

.paper-card>* {
  position: relative;
  z-index: 1;
}

.paper-card .text {
  width: 90%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
}

.info {
  color: #e7a72c !important;
}

.cancel {
  color: #d92d20 !important;
}

.no-cancel {
  color: #039855 !important;
}

.filter-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filter-header p {
  padding: 24px;
}

.filter-input {
  width: 100%;
}

h3 {
  color: white;
}

.filter-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 77px;
  height: 40px;
  /*text-wrap: nowrap;*/
  text-wrap: wrap;
  max-width: 77px;
}

.filter-input label {
  line-height: 1em !important;
}


.table-row:hover {
  background-color: #f0f0f0;
}

.extended {
  background-color: lightgray;
}

.all-reservations-component-buttons {
  height: 40px;
  display: flex;
  justify-content: end;
  gap: 10px
    /* margin-bottom: 10px; */
}

.all-reservations-buttons {
  display: flex;
  flex-direction: row;
}

.all-reservations-add-vehicle-button {
  flex-grow: 1;
}

.title-card-booking {
  color: #344054;
  font-weight: 700;
  margin: 0px !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

a {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #026aa2;
  text-decoration: none;
}

.table-container {
  /* margin-bottom: 30px; */
  border: 5px solid hsl(165, 8%, 63%);
  border-radius: 8px !important;
  box-sizing: border-box;
}

.main-bookings-filter-container {
  border-color: var(--custom-green) !important;
  color: blue;
}

.notification {
  text-align: center;
}

.button-grid {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.button-reset {
  border: 1px solid #883c40 !important;
  width: 82px;
  height: 40px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px, 16px, 10px, 16px !important;
  color: #883c40 !important;
  text-transform: none !important;
}

.button-excel {
  border: 1px solid #883c40 !important;
  width: 160px;
  height: 40px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px, 16px, 10px, 16px !important;
  background-color: whitesmoke !important;
  color: #883c40 !important;
  text-transform: none !important;
}

.filters-button {
  border: 1px solid #883c40 !important;
  width: 160px;
  height: 40px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px, 16px, 10px, 16px !important;
  color: white !important;
  background-color: #883c40 !important;
  text-transform: none !important;
}

.filter {
  width: 50px !important;
  text-align: center !important;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

.filter-label {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

/* .table-row-header > * {
    font-family: IBM Plex Sans !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: left!important;
    color: #475467 !important;
    width: 90px;



} */

/* .table-row > * {
    font-family: IBM Plex Sans !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: left !important;
    color: #475467 !important;
    width: 73px;

} */

.booking-id {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left !important;
  color: #026aa2;
}

.filter-heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  /* width: 1212px; */
  height: 24px;
  gap: 0px;
  /* opacity: 0px; */
}

.booking-number {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #026aa2;
  text-decoration: none;
}

.all-reservations-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* min-width: 1280px; */
  width: 100%;
}

.all-reservations-content-container {
  width: 1500px;
  /* min-width: 1280px; */
  max-width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.reservation-filters-card-container {
  display: flex;
  flex: 1;
  /* justify-content: space-between;
 */
  gap: 5px;
  align-items: center;
  text-align: center;
  /* position: relative; */
  height: 160px;
  box-sizing: border-box;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: white;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px 0px #1018281a;
  border-radius: 8px;
  min-width: 250px;
  /* box-shadow: 0px 1px 2px 0px #1018280F; */
}

.reservation-filters-image-container {
  background: url('../../../../public/assets/login-picture.png');
  width: 80%;
  height: 100%;
  background-size: 170%;
  background-repeat: no-repeat;
  background-position-x: 80%;
  border-radius: inherit;
  border-radius: 8px 36px 36px 8px;
  position: relative;
}

.reservation-filters-image-mask {
  /* background-color: red; */
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 24px;
  text-wrap: wrap;
  display: flex;
  align-items: center;
  border-radius: inherit;
  /* opacity: 40%; */
  box-sizing: border-box;
}

.reservation-filters-image-mask-all {
  background: linear-gradient(45deg,
      rgba(133, 24, 30, 0.8) 0%,
      rgba(184, 149, 151, 0.8) 100%);
}

.reservation-filters-image-mask-canceled {
  background: linear-gradient(45deg,
      rgba(133, 24, 30, 0.9) 0%,
      rgba(58, 22, 24, 0.9) 100%);
}

.reservation-filters-image-mask-uncanceled {
  background: linear-gradient(45deg,
      rgba(11, 56, 42, 0.8) 0%,
      rgba(89, 106, 101, 0.8) 100%);
}

.reservation-filters-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.overided-select .MuiSelect-select {
  padding: 8.5px 8.5px 8.5px 12px;
}

.overided-select label {
  transform: translate(12px, -50%) scale(1);
  top: 50%;
}

.reservation-filters-rop-down-container {
  display: flex;
  flex-direction: column;
  gap: 6px !important;
  flex: 1;
  justify-content: space-between;
}

.reservation-filters-row-container {
  display: flex;
  gap: 16px;
}

.reservation-filters-row-container .custom-input-main-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 6px;
}

.input-container {
  position: relative;
  flex: 1;
}

.error-message {
  position: absolute;
  bottom: -15px;
  left: 0;
  color: red;
  font-size: 12px;
}

.all-reservations-main-container table th,
.all-reservations-main-container table td {
  padding: 12px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #101828;
  text-align: center;
}


.booking-filter-date-time .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 24px;  
  width: 25px;
  height: 25px;
  color:gray;
 
} 

.label-container {
  display: flex;
  margin-bottom: -15px;

}

.check-count-label {
  padding: 0px;
  margin: 0;
  color: #5C7670;
  font-size: 18px;
  font-family: 'Poppins';
}

.react-tel-input .flag-dropdown .search-box {
  padding: 10px 0 10px 5px !important;
  margin-left: 0 !important;
}

.react-tel-input .flag-dropdown {
  padding: 0 !important;
  margin: 0 !important;
}

.react-tel-input .country-list {
  padding: 0 !important;
  margin: 0 !important;
}

.seats-main-container{
  display: flex;
  background-color: rgb(238, 238, 238);
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  font-family: IBM Plex Sans;
  font-size: 19px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
  text-align: center;

}