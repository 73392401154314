.user-profile-page-container {
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: IBM Plex Sans;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.user-profile-main-container {
  width: 1280px;
  min-height: 78vh;
  border-radius: 8px;
  border: 5px solid #98a8a4;
  position: relative;
}
.user-profile-top-cotainer {
  height: 25.3vh;
  background-image: url('../../../../public/assets/login-picture.png');
  background-size: cover;
  background-position: 0% 15%;
  background-repeat: no-repeat;
  width: 100%;
  /* background-color: rgb(87, 67, 67) !important; */
}
.user-profile-mask-cotainer {
  background: linear-gradient(
    90deg,
    rgba(136, 60, 64, 0.8) 0%,
    rgba(80, 7, 15, 0.8) 100%
  );
  height: 25.3vh;
  width: 100%;
  position: absolute;
  /* z-index: 1; */
  top: 0;
  display: flex;
  justify-content: center;
  align-items: end;
}
.user-profile-inittials-container {
  width: 11vw;
  height: 11vw;
  max-width: 160px;
  max-height: 160px;
  background-color: #f2f4f7;
  transform: translateX(0) translateY(50%);

  box-shadow: 0px 12px 16px -4px #10182814;
  box-shadow: 0px 4px 6px -2px #10182808;
  border: 4px solid white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 4.16vw; */

  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
  box-sizing: border-box;
  color: #883c40;
  font-size: clamp(1rem, 4.16vw, 60px);
  line-height: clamp(1.2rem, 5vw, 72px);
}
.user-profile-blank-container {
  height: 5.5vw;
  max-height: 80px;
}
.user-profile-content-container,
.user-profile-name-mail-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.user-profile-content-container {
  padding-top: clamp(2px, 2.5vh, 4px);
  padding-bottom: clamp(0.5rem, 3.37vh, 32px);
  border-bottom: 1px solid rgb(219, 219, 219);
  box-sizing: border-box;
  gap: clamp(10px, 2.1vh, 20px);
}
.user-profile-name-mail-container {
  /* padding-top: 2.5vh; */

  /* gap:2.5vh ; */
  gap: clamp(2px, 0.42vh, 4px);
}
.user-profile-role-phone-actions-container {
  display: flex;
  text-align: center;
  justify-content: center;
}
.user-profile-name-container {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  /* text-align: center; */
  color: #101828;
  font-size: clamp(0.5rem, 2.08vw, 30px);
  line-height: clamp(0.5rem, 2.63vw, 38px);
}
.user-profile-email-container {
  font-size: clamp(0.3rem, 1.11vw, 16px);
  line-height: clamp(0.35rem, 1.66vw, 24px);

  font-weight: 400;
  /* line-height: 24px; */
  color: #475467;
}
.user-profile-role-phone-actions-container {
  font-size: 14px;
  font-weight: 600;

  /* text-align: left; */
  font-size: clamp(0.25rem, 1vw, 14px);
  line-height: clamp(0.25rem, 1.4vw, 20px);
  gap: clamp(10px, 1.11vw, 16px);
  color: #475467;
}
.user-profile-role-phone-actions-container path {
  stroke: #475467;
}
.user-profile-span-container {
  display: flex;
  align-items: center;
}
.user-profile-forms-container {
  width: 100% !important;

  justify-content: center;
  align-items: center;
  display: flex;
}
.user-profile-form-header-container {
  width: 610px !important;
  padding-top: clamp(0.5rem, 3.37vh, 24px);
  display: grid;

  /* justify-content: center;
  align-items: center;
  display: flex; */
}
.user-profie-form-header {
  font-family: Poppins;
  font-size: clamp(0.35rem, 1.66vw, 24px);
  font-weight: 600;
  line-height: clamp(0.5rem, 3.37vh, 32px);

  padding-bottom: clamp(0.5rem, 3.37vh, 32px);

  color: #98a2b3;
}
.user-profile-forms-container .update-user-form-container {
  width: 100% !important;
  gap: 12px !important;
}
.user-profile-forms-container .arac-save-button {
  display: none;
}
.user-profile-forms-container .change-password-buttons-container{
  display: flex !important;
}
.cursor-pointer {
  cursor: pointer;
  /* box-sizing: border-box; */
}
.cursor-pointer:hover{
  background-color: #e7eaee;
}
