.add-route-stop-autocomplete{
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
}
.add-route-stops-main-container{
    display: flex;
    flex-direction: row-reverse;
    position: relative
}
.add-route-stops-new-route-stops{
     /* Adjust as needed */
    width: 250px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap:1px;
    border-left: 1px solid #83111D;
    justify-content: space-between;
    /* height: 100%; */
    /* overflow: scroll; */

}

.add-route-stop-new-stop{
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.5vh;
    margin: 0.5vh;
    margin-top: 0;
    box-shadow: 5px 5px 10px #888888;
}
.add-route-stop-new-stop:hover{
    background-color: rgb(214, 214, 214);

}
.add-route-stops-new-route-stops h3{
    padding: 0;
    margin: 0;
    height: 66px;
    margin-bottom: 10px;
    align-self: center;
    /* text-decoration: underline; */
    display: flex;
    align-items: center;
    justify-content: center;
    color:#83111D;
    border-bottom: 1px solid #83111D;
}
.add-route-stop-autocomplete input{
    width: 20vw;
}
.add-route-stop-icons-container p{
    margin: 0;
    padding: 0;
}
.add-route-stop-icons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.add-route-stop-new-route-stops-button-container{
    display: flex;
    gap: 1vw;
    margin: 0.5vh;

}
.add-new-route-stop-stops{
    height: 75vh;
    overflow-y: auto;
    border-bottom: 1px solid #83111D;

}
.add-new-route-stop-stops::-webkit-scrollbar{
    /* background-color: rebeccapurple; */
    width: 5px;

}
.add-route-stop-on-click-info{
    color: #83111D;
    margin: 0px;
    margin-left: 10px;
    margin-bottom: 10px;

}
.add-new-route-stop-stops::-webkit-scrollbar-thumb {
    background: #83111D; /* Set color of the scrollbar thumb */
}
.add-new-route-stop-stops::-webkit-scrollbar-thumb:hover {
    background: #3f040a /* Set color of the scrollbar thumb */
}
.add-new-route-stop-stops::-webkit-scrollbar-track {
    background: #d3d2d2; /* Set color of the scrollbar track */
}
.add-route-stops-main-container button{
    background-color: #83111D;
}
.add-route-stops-main-container button:hover{
    background-color: #3f040a;
}
@media (max-height: 870px) {
    .add-new-route-stop-stops{
        height: 75vh; 
    }
    /* .add-route-stops-new-route-stops{
        width: 150px;
    } */
    .add-route-stops-new-route-stops h3{
        font-size: 16px;

    }
    .add-route-stops-new-route-stops span{
        font-size: 12px;
    }

    .add-route-stop-autocomplete{
        padding-top: 5px;
        padding-left: 5px;
    }
    .add-route-stop-on-click-info{
        margin-bottom: 5px;
        margin-left: 5px;
    }
    .add-route-stops-main-container p,
    .add-route-stops-main-container button,
    .add-route-stops-main-container input,
    .add-route-stops-main-container label {
        font-size: 10px;
    }

  }


@media (max-height: 860px) {
    .add-new-route-stop-stops{
        height: 61vh; 
    }

  }
/* .slide-in-in-in.show {
    transform: translateX(0); 
    opacity: 1; 
} */
/* @keyframes slide-in {
    from {
        width: 0px;
        display: none;
    }
    to {
        width: 200px;
        display: block;
    }
} */
/* .add-route-stops-new-stops-container {
    animation: slide-in 1s ease-out; 
} */

