.custom-dialog-title,
.custom-dialog-title2 {
  text-align: center;
  display: flex;
  justify-content: center;
}
.custom-dialog-title {
  color: rgba(125, 25, 32, 255);
  border-bottom: 1px solid rgba(125, 25, 32, 255);
}

.custom-dialog-title2 {
  color: white;
  background-color: #83111d;
  border-bottom: 1px solid rgba(125, 25, 32, 255);
  border-bottom: 2px solid #3f040a;
}

.custom-dialog-content-container {
  display: flex;
  flex-direction: column;
}
.custom-dialog-main-container .MuiPaper-root {
  max-width: none;
}

.custom-dialog-content-container::-webkit-scrollbar {
  /* background-color: rebeccapurple; */
  width: 5px;
}
.custom-dialog-content-container::-webkit-scrollbar-thumb {
  background: #83111d; /* Set color of the scrollbar thumb */
}
.custom-dialog-content-container:-webkit-scrollbar-thumb:hover {
  background: #3f040a; /* Set color of the scrollbar thumb */
}
.custom-dialog-content-container::-webkit-scrollbar-track {
  background: #d3d2d2; /* Set color of the scrollbar track */
}
.warning-content-main-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  font-family: 'Roboto', sans-serif; 
  color: #1d2a3a; 
  letter-spacing: 0.5px;
}

.warning-header-container {
  font-size: 24px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
  /* padding-bottom: 6px; */
  /* border-bottom: 2px solid #ffab00 ; */
}

.warning-content-cotainer{
  font-size: 16px !important;
}

.yellow-button{
  background-color: #ffab00 !important;
  /* color: #070a0e !important;  */
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'Roboto', sans-serif; 
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px  16px  10px  16px !important;
  text-transform: none !important;
  border: none;
  cursor: pointer;
}
.yellow-button:hover{
  background-color: #ff9100 !important;
}

.warning-dialog-blank-button{
  gap: 0px;
  /* opacity: 0px; */
  font-family: 'Roboto', sans-serif; 
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
  border-radius: 4px !important;
  padding: 10px  16px  10px  16px !important;
  text-transform: none !important;
  border: none;
  cursor: pointer;
  background-color: white;
}
.warning-dialog-blank-button:hover{
  background-color: rgb(224, 224, 224);
}
.warrning-dialog-button-cotainer{
  display: flex;
  gap: 5px;
  justify-content:flex-end  ;
  

}