.add-subagent{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding-right: 30px !important
}

.add-passenger-button {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    font-size: 16px;
    width: 180px !important;


}
.subagents-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.subagent-header p {
    margin: 0px;
    padding: 0px;
}
.card {
    padding: 15px !important;
}

.title-card 
{   
    font-family: Poppins !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    gap: 0px;
    /* opacity: 0px; */
    color: #344054 !important;
    padding-left: 15px;
}

.table-subagent {
    border: 5px solid #98A8A4;
    border-radius: 10px;
    box-sizing: border-box;
    height: 525px;
    display: flex;
    width: 1280px;
    flex-direction: column;
}

.table-subagent .table-container {
    flex: 1;
}

.pagination-bottom {
    margin-top: auto;
}

.subagent-header {
    display: flex;
    justify-content: space-between;
    width: 1280px;
    max-width:1280px;
}

.payment-method-button {
    margin-right: 30px !important;
    background-color: #883C40 !important;
    color: white !important;
    font-family: IBM Plex Sans !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: left !important;
    text-transform: none !important;

}