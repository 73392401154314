.dialog-delete {
    gap: 10px !important;
    display: flex;
    justify-content: end !important;
    margin-top: 20px !important;
  
}

#textDelete {
    color:black;
    margin: 5px;
}

.dialog-delete > button {

    color: white !important;
    background-color: #883C40 !important;
    text-transform: none !important;   
}