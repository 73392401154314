.container {
    display: flex;
    height: 100vh;
    margin: 0 !important;
  }
  
  /* Left side styles */
  .left {
    height: 100vh;
    position: relative;
    width: 62.5%;
    background: url('../../../public/assets/login-picture.png') no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    background-position: right; /* calc(100% + 50px) center */
    overflow: hidden;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(58, 22, 24, 0.8), rgba(133, 24, 30, 0.8));
    opacity: 0.8;
    border-radius: 0 80px 80px 0;
  }
  
  .overlay img {
    position: absolute;
    bottom: 70%;
    left: 80%;
    transform: translateX(-50%);
    width: 80px;
    height: auto;
  }
  
  .content-container {
    position: absolute;
    bottom: 0px;
    width: 70%;
    height: 234px;
    max-height: 234px;
    z-index: 1;
    color: white;
    padding-left: 120px;
    padding-right: 120px;
  }
  
  .content-title .big-title,
  .content-title .small-title {
    text-align: start;
    font-family: 'Inter', sans-serif;
  }
  
  .big-title {
    margin: 0;
    height: 180px;
    font-size: 56px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 1px;
  }
  
  .small-title {
    margin: 0;
    height: 30px;
    font-weight: 500;
    line-height: 30px;
  }
/* Right side styles */
.right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 37.5%;
    height: 100vh;
}

.login-form {
    width: 100%;
    height: 450px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form img {
    border-radius: 50%;
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.login-form p {
    text-align: center;
    height: 24px;
}

.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-row a {
    font-weight: 600;
    font-size: 14px;
    color: rgba(133, 24, 30, 1);
    text-decoration: none;
}

.remember-me {
    display: flex;
    align-items: center;
}

.forgot-password {
    text-align: right;
}

.form-style {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.text-label {
    margin-bottom: 5px;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.input-pass {
    width: 100%;
    padding-right: 40px;
    /* Adjust padding to prevent text from overlapping with the icon */
}

.password-toggle {
    position: absolute;
    top: 50%;
    right: 15px;
    /* Adjust to your preference */
    transform: translateY(-50%);
    cursor: pointer;
}

.input-text,
.input-pass {
    width: 95%;
    height: 44px;
    padding: 10px;
    margin-bottom: 2px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.input-pass{
    width: 95%;
}

.log-button {
    padding: 10px;
    background-color: rgba(11, 56, 42, 1);
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
}

.log-button:hover {
    background-color: #45a049;
}

.footer-text {
    position: absolute;
    bottom: 0px;
    width: 35%;

    text-align: center;
    padding: 10px;
    /*background-color: #f1f1f1;*/
}

.helper-text {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
}

.input-error {
    border-color: red;
}

.header-language {
    font-family: 'Poppins', sans-serif;
  
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 20px;

  }
  .header-language span {
    
    background-color: #e3e6ea88;
    padding: 10px;
    border-radius: 200px;
    color:#3f040a;
    cursor: pointer;

  }

/*MEDIA QUERIES*/
@media (max-width: 1200px) {
    .big-title {
        font-size: 2.5rem;
    }

    .small-title {
        font-size: 1.3rem;
    }
}

@media (max-width: 785px) {
    .left {
        background-position: center;
    }

    .overlay img {
        left: 50%;
        transform: translateX(-50%);
        bottom: 10%;
    }

    .content-container {
        top: 30%;
    }

    .big-title {
        font-size: 2rem;
    }

    .small-title {
        font-size: 1.2rem;
    }

    .login-form {
        padding: 10px;
    }
}

@media (max-width: 700px) {
    .big-title {
        font-size: 1.5rem;
    }

    .small-title {
        font-size: 1rem;
    }
}

@media (max-width: 530px) {
    .big-title {
        font-size: 1.2rem;
    }

    .small-title {
        font-size: 0.7rem;
    }

    .content-container {
        height: 100px;
        padding: 0 8px;
    }
}