.input-autocomplete-predictions {
  background-color: rgb(240, 240, 240);
  /* width: 10vw; */
  /* width: 100%; */
  /* max-width: 200px; */
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  position: absolute;
  /* top:100%; */
  z-index: 1;
  text-wrap:wrap;
  text-overflow: ellipsis;
    overflow: hidden;
  font-family: "IBM Plex Sans", sans-serif;
  z-index: 2;

}
.input-autocomplete-predictions svg{
   color:rgb(194, 194, 194);
  }
.input-autocomplete-predictions li {
  font-family: "IBM Plex Sans", sans-serif;
    
    display: flex;
    align-items: center;
    /* text-align: center; */
    font-size: 0.9rem;
    padding: 1vh;
    gap:0.5vw;
    border-bottom: 0.1vh solid rgb(194, 194, 194);
    /* justify-content: center; */
    
}
.input-autocomplete-predictions li.focused svg,
.input-autocomplete-predictions li:hover svg{
    color: red;
}
.input-autocomplete-predictions .custom-focused,
.input-autocomplete-predictions .custom-focused:hover,
.input-autocomplete-predictions li.focused,
.input-autocomplete-predictions li:hover{
    background-color: rgb(212, 212, 212);
    cursor: pointer;
}
.input-autocomplete-predictions .custom-focused svg,
.input-autocomplete-predictions .custom-focused:hover svg{
    color: rgb(23, 101, 189) !important;
}
.custom-focuseddd:hover svg{
  color: rgb(23, 101, 189) !important;

}
.autocomolete-icon-container-selected,
.autocomolete-icon-container{
  position: absolute;
  /* height: 100%; */
  top: 50%;
  left: 90%;
  background-color: white;
  
}
.input-autoctomplete-google-main-container input:disabled,
.input-autoctomplete-google-main-container input::placeholder{
  color: rgb(194, 194, 194) !important;

}

.autocomolete-icon-container-selected:hover path{
  stroke: red;
  cursor: pointer;
}
.custom-focuseddd svg{
  color: rgb(23, 101, 189) !important;

}
.not-custom-autocomplete-location svg{
  color: red;
}