.add-user-form-main-container {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    /* width: 20vw; */
    /* margin-top: 10px; */
    min-width: 370px;
    gap:12px;
    max-width: 500px;
    /* overflow: auto; */
}
.add-user-form-twofields-container{
    display: flex;
    gap:1vw;
    margin-bottom: 10px;
    /* width: 100%; */
  }
  .add-user-form-twofields-container{ 

    /* overflow: hidden; */
  }
  .add-user-form-onefield-cotaniner{
    flex: 1;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    padding-top: 5px;

  }
  .add-user-form-phone-roles-container{
    padding-bottom: 10px;
  }
  .add-user-form-onefield-cotaniner label{
    /* overflow: visible; */
  }
 /* .add-user-form-twofields-container span{ 
    overflow: hidden;
    display: none;
  }
  .add-user-form-twofields-container input{ 
    width:auto;
  } */