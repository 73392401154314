/* .user-item {
    display: flex;
    flex-direction: row;
    height: 5px;
    margin: 10px;
}

.user-prop {
    margin-right: 10px;
}


.update-container {
    margin: 8vh;
    margin-top: 0;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;

} */
.update-user-main-container{
    display: flex;
    flex-direction: column;
}
.update-user-dialog-tiitle
{
    display: flex;
    justify-content: center;
    color:"secondary"
}
/* .update-user-form-container{
    padding-left:3vw;
    padding-right:3vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
} */

/* @keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
} */
 .update-user-form-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    width: 300px;
 }