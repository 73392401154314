.eror404-main-container{
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(249,249,247);
}
.eror404-photo-container{
    width: 600px;
    height: 500px;
    /* background-color: rebeccapurple; */
    background-image: url('../../../public/404Error.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.eror404-content-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.eror404-content-container h2{
    color: rgb(1,32,59);
    padding: 0;
    margin: 0;
}
.error404-header-button-conteiner{
    display: flex;
    gap: 30px;
    margin-top: 10px;
}