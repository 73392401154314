.accordion {
    margin-bottom: 2vh;
    border-color: var(--custom-green) !important;
}

.accordion-summary {
    background-color: var(--custom-sage) !important;
    color: white !important;
    height: 3vh !important;
    border: 1vh solid var(--custom-sage);
    border-radius: 1vh !important;
}

.Mui-expanded .accordion-summary {
    border-bottom-left-radius: 0vh !important;
    border-bottom-right-radius: 0vh !important;
}

.accordion-details {
    border: 1vh solid var(--custom-sage);
    border-bottom-left-radius: 1vh !important;
    border-bottom-right-radius: 1vh !important;
}