.rides-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.rides-content-container {
  width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 100%;
  box-sizing: border-box;
}
.passengers-table-booking-id {
  color: #026aa2 !important;
  cursor: pointer;
}
.passangersBorderColor-true {
  border-left: 8px solid #fff6de;
  box-sizing: border-box;
}
.passangersBorderColor-false {
  border-left: 8px solid #9dada9;
  box-sizing: border-box;
}
.booking-padding-secondary {
  padding-right: 5px !important;
}
.manage-drivers-main-container {
  width: 350px;
  /* height: 600px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding: 8px;
  border: 8px solid #5c7670;
  border-top: 0px;
}
.assigned-drivers-main-container {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  background-color: rgb(248, 248, 248);
  /* margin-top: 10px; */
  height: 100px;
}
.assigned-driver-main-container-new,
.assigned-driver-main-container {
  max-height: 32px;
  border-radius: 8px;
  padding: 8px 10px 8px 10px;
  box-sizing: border-box;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.assigned-driver-main-container {
  background-color: #ecfdf3;
  color: #027a48;
  border: 1px solid #027a48;
}
.assigned-driver-main-container-new {
  color: rgb(189, 121, 0);
  background-color: rgb(255, 244, 225);
  border: 1px solid rgb(189, 121, 0);
}
.no-assigned-drivers-message {
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
}
.no-result-found-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  font-family: 'Poppins', sans-serif;
  align-items: center;
  color: #344054;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.change-vehicle-main-container {
  display: flex;
  gap: 15px;
  padding: 8px;
  padding-top: 16px;
  border: 8px solid #5c7670;
  border-top: 0px;
  justify-content: center;
  flex-direction: column;
  width: 300px;
}
