.confirmation-dialog-button-cotainer{
    display: flex;
    justify-content: end !important;
    width: 100%;
    justify-content: center;
    color: rgba(125,25,32,255);
    padding-top: 10px;
    gap: 5%;

    /* color:#ffe3e6 */
}