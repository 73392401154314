.main-create-booking-container {
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 1280px;
  padding-bottom: 32px;
}

.create-booking-content-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  width: 1280px;
  max-width: 1280px;
  gap: 32px;
}

.booking-page-title {
  justify-content: center;
}

.add-passenger {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
  gap: 1vh;
}

.passenger-info {
  /* width: 70%; */
  margin-right: 1vh;
}

.add-passenger-button {
  width: 170px !important;
  height: 40px;
  gap: 8px;
  border-radius: 4px;
  /* opacity: 0px; */
  background-color: #883c40 !important;
  height: 5.7vh;
  margin: 3.7vh 0 3.7vh 0 !important;
  text-transform: none !important;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 14px;
}

.route-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
  margin-top: 1px;
}

.route-item {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.route-card {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  height: auto;
  height: 155px;
  justify-content: space-between;
}

.return-switch {
  color: var(--terra-red) !important;
}

h5 {
  margin: 0;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.switch-item {
  flex: 1;
  /* text-align: center !important; */
  align-items: center;
  justify-content: center;
}

.route-item input {
  padding: 8px 8px 8px 12px !important;
}

.switch-style {
  /* justify-content: end;
    align-items: end;
    align-content: end; */
  /* margin-left: 1vh; */
  margin-top: 2vh;
  align-self: flex-start;
}

.switch-item h5 {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.checkbox-style {
  /* justify-content: end;
    align-items: end;
    align-content: end; */
  /* margin-left: 1vh; */
  margin-top: 2vh;
  align-self: flex-start;
  color: var(--terra-red) !important;
}

.directions {
  display: flex;
  /* gap: 4vh; */
  gap: 40px;
  justify-content: space-between;
  width: 100%;
}

.direction-input-autocomplete {
  /* flex: 2 0 !important; */
  min-width: 220px;
}

.direction-info {
  /* width: 48.5%; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: calc(50% - 20px);
  flex: 1;
}

.times-info-div {
  display: flex;
  flex: 1;
  gap: 2vh;
  width: 100%;
}

.time-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.address-info-div {
  display: flex;
  flex: 1;
  gap: 10px;
  width: 100%;
  /* margin-bottom: 1vh; */
}

.add-address {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.error-text {
  color: #d32f2f;
}

.remove-red-border fieldset {
  /*border-color: initial !important;*/
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.map-icon:hover {
  cursor: pointer;
}

.map-icon {
  width: 50px !important;
}

.night-div {
  display: flex;
  /* justify-content: space-between; */
  height: 100%;
  flex-direction: column;
  text-wrap: nowrap;
  align-items: center;
}

.delete-button {
  color: dimgray;
}

.delete-button:hover {
  cursor: pointer;
}

.edit-button {
  color: var(--terra-red);
}

.edit-button:hover {
  cursor: pointer;
}

.swap-direction:hover {
  cursor: pointer;
}

.swap-direction {
  width: Hug (28px) px;
  height: Hug (28px) px;
  padding: 4px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  /* opacity: 0px; */
  width: 20px;
  height: 20px;
}

.add-reservation-button {
  margin-left: 50px;
  /* margin-top: 15px !important; */
  width: Hug (190px) px;
  height: Hug (40px) px;
  padding: 10px 32px 10px 32px !important;
  gap: 8px;
  border-radius: 4px;
  /* opacity: 0px; */
  background: #883c40 !important;
  border: 1px solid #83111d !important;
  text-transform: none !important;
  color: white !important;

  /* color: #83111D; */
}

.add-reservation-button:disabled {
  color: gray !important;
  background-color: #f0f0f0 !important;
  border: 1px solid gray !important;
  cursor: default;
}

.add-reservation-button:hover {
  background-color: var(--terra-red) !important;
  /* color: #83111D; */
}

.passenger-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.passenger-button {
  display: flex;
  flex-direction: column;
  align-items: end !important;
  margin-top: 45px;
}


.add-city-button {
  color: var(--terra-red) !important;
  justify-content: flex-start !important;
}

.car-rental-checkbox {
  display: flex;
  text-align: center;
}

.form-control-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-left: 0;
  margin-right: 2px;
  padding: 10px;
  gap: 8px;
}

.form-control-label .MuiFormControlLabel-label {
  font-weight: 600;
  font-size: large;
}

.page-title {
  display: flex;
  gap: 10px;
}

.history {
  margin-top: 25px;
}

.table-buttons {
  display: flex;
  justify-content: end;
}

.route-booking {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5vh;
  width: 100%;
}

.route-booking>* {
  font-family: IBM Plex Sans !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--Gray-600, #344054) !important;
}

.booking-label {
  width: 120px;
  height: 20px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--Gray-700, #344054);
}

.passenger-table {
  border: 1px solid var(--Gray-200, #eaecf0);
}

.table-cointainer {
  border-radius: 4px;
}

.table-head>* {
  font-family: IBM Plex Sans !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: var(--Gray-600, #475467) !important;
}

.table-body>* {
  font-family: IBM Plex Sans !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--Gray-600, #475467) !important;
}

.route-heading {
  /* font-family: Inter !important;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  text-align: left;
  */
  font-family: Poppins !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  width: 1272px;
  height: 32px;
  gap: 0px;
  /* opacity: 0px; */
  color: #344054 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px !important;
}

.direction {
  width: Fixed (261px) px;
  height: Hug (28px) px;
  gap: 2px;
  /* opacity: 0px; */
  display: flex;
  padding: 10px;
  font-family: Inter !important;
}

.route-title {
  font-weight: 700;
  font-size: 18px;
  font-family: Inter !important;
}

.time-div h5 {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 110px;
  height: 20px;
  gap: 0px;
  /* opacity: 0px; */
  color: #344054;
  width: 100%;
}

.times-info-div .booking-filter-date-time p {
  margin: 0px;
}

.booking-data {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 110px;
  height: 20px;
  gap: 0px;
  /* opacity: 0px; */
  color: #344054;
}

.time-div .label {
  display: flex;
  flex-direction: column;
}

.night-info {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  width: Hug (58px) px;
  height: Fill (66px) px;
}

.route-subagent {
  width: 100%;
}

.passenger-item .MuiAutocomplete-hasPopupIcon.css-1jc8tp2-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 0px;
  padding: 0px;
  width: 100%;
}

.passengers-autocomplete-main-conteiner p {
  color: green;
  padding: 0 !important;
  margin-left: 0 !important;
  position: absolute;
  bottom: -20px;
}

.passangers-fields-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  flex: 1;
  gap: 14px;
}

.passangers-fields-container input {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  display: flex;
  gap: 8px;
  padding: 8px 8px 8px 12px !important;
  max-height: 40px;
}

.passangers-fields-container .MuiOutlinedInput-root {
  padding: 0px !important;
}

.text-area-passangers-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.text-area-passangers-container textarea {
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  /*100%;*/
  max-height: none !important;
  resize: none;
}

.min-height-applied {
  min-height: 175px;
}

.passangers-component-main-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
}

.add-reservation-travel-data-header {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.create-reservation-button-container {
  display: flex;
  justify-content: end;
}
/* .create-reservation-button-container button :disabled{
  color: gray !important;
  background-color: #f0f0f0 !important;
} */

.create-reservation-routes-autocomplete-container,
.subagent-dropdown-field {
  flex: 1;
}

.create-reservation-travel-info {
  display: flex;
  width: 100%;
  gap: 16px;
}

.create-reservation-switch-container {
  width: 140px;
}

/* .select-option-style{
  font-family: IBM Plex Sans !important;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--Gray-600, #475467) !important;
  padding: 10px;
} */

select option {
  color: black;
}

select:not(:checked) {
  color: gray;
}

.passangers-auctocomplete-text-field p {
  margin: 0px !important;
  position: absolute;
  transform: translateY(200%);
}

/* option:hover,
option:focus,
option:active,
option:checked{
  background-color: rebeccapurple;
} */
.direction-component-input-overide {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 6px;
}

.direction-component-input-overide input {
  width: 100%;
  box-sizing: border-box;
}

.direction-info .custom-input-main-container {
  display: grid;
  gap: 6px;
}

.direction-info .input-autoctomplete-google-main-container .autocomolete-icon-container,
.direction-info .input-autoctomplete-google-main-container .autocomolete-icon-container-selected {
  left: 87% !important;
  top: 39px !important;
}

.direction-info .input-autoctomplete-google-main-container input {
  margin-top: 5px;
}

.direcation-component-small-container {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  position: absolute;
  margin-top: 70px;
  width: 245px;
  margin-bottom: 5px;
}

.phone-num-text-field {
  position: relative;
}

.phone-num-text-field .react-tel-input {
  width: 100%;
}

.phone-num-text-field .react-tel-input .flag-dropdown {
  margin-right: 15px;
}

.phone-num-text-field .react-tel-input .form-control {
  padding-left: 50px !important;
  text-align: left;
  position: relative;
}

.phone-num-text-field .react-tel-input .flag-container {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.first-number-field {
  position: relative;
}

.add-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-130%);
  left: calc(75% + 5px);
}

.add-number-button {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.add-number-button button {
  justify-content: space-around;
  align-items: center;
  text-transform: none;
  max-width: 30%;
  text-wrap: nowrap;
  font-size: 12px;
  width: 30%;
  border-radius: 4px;
}

.add-number-button button:hover {
  border-color: transparent;
}

.second-number-field {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  display: inline-block;
  vertical-align: middle;
}

.second-number-field.visible {
  width: 70%;
  max-height: 100px;
  opacity: 1;
}

.second-number-field.hidden {
  max-height: 0;
  opacity: 0;
}

.remove-number-button {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 50px;
}

.remove-number-button button {
  text-wrap: nowrap;
  text-transform: none;
  font-size: 12px;
  border: none;
  background: none;
  padding: 0;
  margin-top: 5px;
}

.button-history {
  width: Hug (134px)px;
  height: Hug (40px)px;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
  color: #883C40 !important;
  font-family: IBM Plex Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: left !important;
  text-transform: none !important;
}

.second-number-field {
  position: relative;
  overflow: visible;
}

.car-rental-input-text-container p{
  margin: 0px;
}

.booking-filter-date-time input {
  font-family: 'IBM Plex Sans', sans-serif;
  color: rgb(16, 24, 40);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid rgb(255, 255, 255);
  display: flex;
  gap: 8px;
  padding: 8px 8px 8px 12px;
  max-height: 40px;
  height: 40px;
  box-sizing: border-box;
}
.booking-filter-date-time input:focus{
  border-color: black !important;
}
.booking-filter-date-time {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}