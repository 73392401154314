.pointer {
  cursor: pointer;
  /* color:rgb(255, 123, 123) */
  /* color: rgb(197, 197, 197); */
}
.route-table-status-true,
.route-table-status-false {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
}
.route-table-status-true {
  background-color: #ecfdf3;
  color: #027a48;
  width: 5rem;
}
.route-table-status-false {
  background-color: #fef3f2;
  color: #b42318;
  width: 6rem;
}
.route-table-main-container th {
  background-color: #F9FAFB;
  font-family: IBM Plex Sans !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 12px !important;
  text-align: center ;
  color: #475467 !important;
  padding: 12px 24px 12px 24px !important;
}

.route-table-main-container td {
  /* background-color: #EAECF0; */
  font-family: IBM Plex Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #475467 !important;
  /* padding: 13px 24px 13px 24px !important; */
}
.svg-class-change path {
  stroke: #919dad;
}
.svg-class-change svg{
  width: 20px;
  height: 20px;
}


.left-table-cell-route-table th{
  text-align: left !important;
}
