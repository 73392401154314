.filters-component-top-container{
    /* height: 36px; */
    background-color: #5C7670;
    border-radius: 8px 8px 0px 0px;
    padding: 12px 24px 0px 24px;
}
.filters-component-main-container{
    width: 100%;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
}
.filter-component-header-container{
  font-family: "IBM Plex Sans", sans-serif;

    /* font-family: 'Inter'; */
    padding: 0;
    margin: 0;
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
    color: white;
    text-align: left;
}

.filters-component-top-content-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters-component-content-container{
    transition: gap 1s ease, padding 0.3s ease;

    border-radius: 0px 0px 8px 8px;
    border: 10px solid #5C7670;
    box-shadow: 0px 1px 3px 0px #1018281A;
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    gap: 24px;
    background-color: white;
    
    /* flex-direction: column; */
    overflow: visible;
    min-width: 100px;
}
.route-filters-three-fields-container{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    /* flex-direction: row; */
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 24px;
    flex-wrap: wrap;
}
.route-filters-three-fields-container > * {
    flex: 1; /* Each child will take up an equal amount of space */

    max-width: 100%; /* Ensure it doesn't grow beyond its container */
}

.route-filters-three-fields-container input,select {
  width: 100%; /* Ensure the input field takes the full width of its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  /* min-width: 200px; */
}
.filters-component-buttons-container{
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
    gap:10px;
    width:  100%;
    display: flex;
    justify-content: flex-end;
}
.second-class-name{
    overflow: hidden;
    transition: max-height 1s ease, padding 0.3s ease, border-top 0.3s ease;
    padding: 0px;
    max-height: 0px;
    border-top: 0px;
}
.second-top-name{
    border-radius:8px !important;
    
}

.filter-component-arrow{
    cursor: pointer;
    transition: transform 0.3s ease;


}
.filter-component-arrow.open{
    transition: transform 0.3s ease;
    transform: rotate(180deg);

}