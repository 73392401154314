.google-map-container{
    aspect-ratio: 1.5/1;
    height:80vh ;
    margin: 10px;
    max-width: 100%;
    max-height: 100%;
}

@media (max-height: 850px) {
    .google-map-container{
        height: 70vh;
        margin: 5px;
    }
  }