.custom-locations-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  /* min-height: calc(100vh - 75px); */
  align-items: center;
}
.custom-locations-main-container svg{
  cursor: pointer;
}
.custom-locations-content-container {
  width: 1280px;
  display: flex;
  flex-direction: column;
  /* row-gap: 2.75vh; */
  gap: 32px;
  /* : 82%; */
}
.custom-location-button-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.page-header-container {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  /* width: 1272px;
  height: 32px; */
  gap: 0px;
  /* opacity: 0px; */
  color: #344054 !important;
  /* margin-top: 20px; */

}
.table-style{
    box-shadow: none !important;
}
.table-style table,
.table-style tr,
.table-style th,
.table-style td,
.table-style table {
    font-family: 'IBM Plex Sans', sans-serif;
    /* font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important; */
}
.table-style thead{
    background-color:#F9FAFB ;
}
.custom-location-table-cotainer{
    /* padding: 5px; */
    border-radius: 8px;
    border: 5px solid  #98A8A4;
}
