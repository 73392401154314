.card-body {
    margin: 20px;
    
}
.card-title {
    margin: 20px;
    color: black;
}

.payment-select {
    width: 100%;
}

.card-table {
    margin: 0px 20px 20px 20px;
}

.card-details {
    display: flex;
    justify-content: space-between;
}