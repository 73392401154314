.validation-error-span{
width: 100%;
color: red !important;
font-size: 12px;
max-height: 32px;
overflow: "hidden"
        
}
/* width: "100%",
color: "red",
fontSize: "12px",
// height: "16px",

// whiteSpace: "nowrap", // Prevents text from wrapping to a new line
overflow: "hidden", // Hides overflow content
textOverflow: "ellipsis", */