.dialog-delete {
    gap: 5px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  
}

#textDelete {
    color:black;
    margin: 5px;
}

.dialog-delete > button {
    color: white !important;
    background-color: #883C40 !important;
    text-transform: none !important;   
}