.register-form-data-field {
  padding-bottom: 10px;
}
.register-form-data-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.register-form-button {
  margin: 10px;
  justify-content: center;
}
.registation-invitation-main-container {
  display: flex;
  height: calc(100vh - 60px);
  justify-content: center;
  align-items: center;
  /* background-color: #83111d; */
}
.registation-invitation-header-container h1{
  padding: 0;
  margin:  0;
  color: #83111d;
  text-align: center;

}

.registation-invitation-data-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: white;
  border: 1px solid #83111d;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(131, 17, 29, 1);
  /* background-color: rgb(207, 207, 207); */
  /* height: 50%; */
  /* padding: 60px; */
  /* height: 100%; */
}
/* .registation-invitation-data-container  {
  background: white;
} */

.phone-s .special-label {
  color: #83111d;
  /* font-weight: 600 !important; */
}
/* .phone-s{
width: 100% !important;
border-color: #83111D !important;
color:#83111D !important;
} */

.phone-s input[type="tel"]:hover{
  border: 1px solid black !important;
}
.phone-s input[type="tel"]::placeholder {
  /* color: #83111d; */
  font-style: italic; 
}
.phone-s input[type="tel"]:focus {
  border:1px solid #acb0b8 !important;
  outline: none !important;
  box-shadow: none;
  box-sizing: border-box !important;
}
