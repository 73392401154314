.calendar {
    /* width: 300px; */
    margin: 20px auto;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .calendar-day {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .calendar-day:hover {
    background-color: #f0f0f0;
  }
  
  .calendar-day.selected {
    background-color: red;
    color: white;
  }
  .restricted-date-dialog-main-container{
    display: flex;
    gap: 10px;
    /* padding: 8px; */
    border: 8px solid #5c7670;
    border-top: 0px;
    flex-direction: column;
  }
  .restrict-date-picker-container{

  }
  .dropdown-restricted-data-container{
    /* border-bottom: 3px solid #5c7670; */
    padding-bottom: 8px;
    padding: 8px;
  }
  .restrit-date-header-container{
    background-color: #5c7670;
    padding: 12px;
    padding-left: 16px;
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
    color: white;
    text-align: left;
    font-family: "IBM Plex Sans", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .restrict-date-custom-date-picker .MuiPickersDay-root.Mui-selected {
    background-color: #5c7670 !important; /* Your custom background color */
    color: white; /* Custom text color */
  }
  
  /* Optional: Change hover effect if needed */