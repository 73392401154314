.text-area-with-auto-complete-ul {
  position: absolute;
  margin: 0;
  z-index: 2000000;
  background-color: white;
  border: 1px solid #acb5c5;
  min-width: 250px;
  /* top:0%; */
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.466);
  animation: fadeInSlideDown 0.3s ease forwards; /* Use the animation defined */
  opacity: 1; /* Ensure it remains visible after animation */
  /* overflow: scroll; */
}

.text-area-with-auto-complete-li {
  padding: 10px;
  /* margin: 10px; */
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgb(238, 238, 238);
}
.text-area-with-auto-complete-li:hover {
  background-color: rgb(238, 238, 238);
}
/* 
.filter-component-text & .MuiFormControl-root {
  height: 100%;
} */

@keyframes fadeInSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1; 
      transform: translateY(0); 
    }
  }

  @media (max-height: 900px) {
    .text-area-with-auto-complete-li {
      padding: 5px;
    }
    
  }
  /* @media (max-height: 750px) {
    .text-area-with-auto-complete-ul{
      top:0;
    }
    
  } */