.custom-text-field22 {
  font-family: 'IBM Plex Sans', sans-serif;
  /* width: 250px; */

  color: #98a2b3 !important;
  /* border-color: #D0D5DD !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  display: flex;
  gap: 8px;
  padding: 8px 8px 8px 12px;
  max-height: 40px;
}
.custom-text-field:focus {
  border: 1px solid #344054;
  color: #344054 !important;

  /* border-color: #888888 !important; */
}
.custom-label {
  font-family: 'IBM Plex Sans', sans-serif;

  color: #344054;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}
.react-tel-input .special-label {
  display: none !important;
}
.phone-s input {
  font-family: 'IBM Plex Sans', sans-serif;

  background-color: transparent !important;
  font-size: 1rem !important;
  padding: 8.5px 14px 8.5px 50px !important;
  border-color: #D0D5DD !important;
  /* outline:invert; */
}
.phone-s input:focus{
   border-color: #344054 !important;
   outline: none !important;
}
.custom-route-title-autocomplete input::placeholder,
.custom-input-main-container input::placeholder,
.custom-input-main-container input:disabled{
  color: rgb(194, 194, 194) !important;
}
.custom-route-title-autocomplete{
  min-width: 150px;
}
/* .react-tel-input{
   background-color: transparent;
} */
/* .custom-drop-down-div-main-container{
   display: flex;
   flex-direction: column;
}
.custom-drop-down-div-main-container select{
   
} */
