.sms-statuses-main-container {
  height: 500px;
  width: 380px;
  /* background-color: rebeccapurple;
     */
  /* border: 2px solid var(--custom-green); */
  box-sizing: border-box;
  /* display: grid; */
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
  overflow-y: scroll;
  border: 4px solid #5c7670;
}
.sms-status-container {
  border: 1px solid #98a8a4;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}
.sms-record-main-container:hover,
.sms-status-reservation-id:hover {
  opacity: 0.7;
  cursor: pointer;
}
.sms-status-reservation-id {
  color: #0b382a;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}
.sms-status-status {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  text-align: center;
  padding: 2px 20px 2px 20px;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
}
.sms-status-status-unproccesed{
  color: rgb(189, 121, 0);
  background-color: rgb(255, 244, 225);;

  border: 1px solid rgb(189, 121, 0);

}
.sms-status-status-sent {
  background-color: #ecfdf3;
  color: #027a48;
  border: 1px solid #75bb9e;
}
.sms-status-status-not-sent {
  background-color: #fef3f2;
  border: 1px solid #d92d20;
  color: #d92d20;
}
.sms-status-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.status-number-date-container {
  color: rgb(161, 161, 161);
}
.sms-status-reservaationid-container {
  width: 100%;
  display: flex;
  gap: 5px;
  padding-bottom: 8px;
  /* border-bottom: 1px solid var(--custom-green); */
  justify-content: space-between;
  /* align-items: center; */
}
.sms-status-reservation-text-container {
  font-family: IBM Plex Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  display: flex;
  align-items: center;
  gap: 5px;
}
.sms-statuses-main-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.sms-statuses-main-container::-webkit-scrollbar-thumb {
  background: #83111d; /* Color of the scrollbar thumb */
}

.sms-statuses-main-container::-webkit-scrollbar-thumb:hover {
  background: #3f040a; /* Color of the scrollbar thumb when hovered */
}

.sms-statuses-main-container::-webkit-scrollbar-track {
  background: #d3d2d2; /* Color of the scrollbar track */
}

.sms-status-data-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sms-record-main-container {
  width: 100%;
  box-sizing: border-box;
  /* margin: 5px; */
  gap: 5px;
  /* padding: 0px 12px 0px 12px; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #5c7670;
  margin-bottom: 10px;
  border-radius: 8px;
}
.progress-bar-percentage-container {
  width: 100%;
  position: absolute;
  z-index: 5;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  color: #475467;
  font-weight: 500;
}
.date-time-sms-record-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sms-record-number-of-sent-messages {
  display: flex;
  align-items: center;
  justify-content: end;
}
.sms-record-title-exit {
  display: flex;
  /* border-radius: inherit; */
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  background-color: #5c7670;
  color: white;
  /* padding: 5px; */
  font-family: IBM Plex Sans;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}
.sms-record-title {
  font-weight: 600;
}
.sms-record-conntent-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
}
.failed-sms-record-status {
  color: #ff020f;
}
.failed-sms-record-status,
.record-done-sms-sending {
  font-size: 12px;
  font-weight: 500;
}
.record-done-sms-sending {
  color: #475467;
}
.sms-record-date-time {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
}
.sms-statuses-top-container {
  background-color: #5c7670;
  padding: 8px 12px 8px 12px;
  border: 8px solid #5c7670;
  box-sizing: border-box;
  color: white;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sms-status-top-header {
}

.statuses-sms-message-container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color:"#ff020f"
}

.statuses-sms-resend-container{
  color: white;
  background-color: #5c7670;
  padding: 5px;
  padding-top: 1px;
  display: flex;
  justify-content: end;
}