.route-autocomplete-cotainer {
  width: 100%;
}
.route-autocomplete-cotainer input {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #101828;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  display: flex;
  gap: 8px;
  padding: 7.5px 8px 7.5px 12px !important;
  max-height: 40px;
}
.route-autocomplete-cotainer .MuiOutlinedInput-root {
  padding: 0px !important;
}
