.edit-button {

    color: white !important;
    background-color: #883C40;
    text-transform: none !important;   
}

.errorText {
    color: #d32f2f;
    font-size: 12px;
    margin: 10px;
}