.my-container {
  display: flex;
  height: 100vh;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Left side styles */
.my-left {
  height: 100vh;
  position: relative;
  width: 62.5%;
  background: url('../../../public/assets/login-picture.png') no-repeat center
    center;
  background-size: cover;
  background-attachment: fixed;
  background-position: right; /* calc(100% + 50px) center */
  overflow: hidden;
}

.my-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(58, 22, 24, 0.8), rgba(133, 24, 30, 0.8));
  opacity: 0.8;
  border-radius: 0 80px 80px 0;
}

.my-overlay img {
  position: absolute;
  bottom: 70%;
  left: 80%;
  transform: translateX(-50%);
  width: 80px;
  height: auto;
}

.my-content-container {
  position: absolute;
  bottom: 0px;
  width: 70%;
  height: 234px;
  max-height: 234px;
  z-index: 1;
  color: white;
  padding-left: 120px;
  padding-right: 120px;
}

.my-content-title .my-big-title,
.my-content-title .my-small-title {
  text-align: start;
  font-family: 'Inter', sans-serif;
}

.my-big-title {
  margin: 0;
  height: 180px;
  font-size: 56px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1px;
}

.my-small-title {
  margin: 0;
  height: 30px;
  font-weight: 500;
  line-height: 30px;
}
/* Right side styles */
.my-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.my-login-form {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.my-login-form img {
  border-radius: 50%;
}

.my-login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.my-login-form p {
  text-align: center;
  height: auto;
}

.my-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.my-form-row a {
  font-weight: 600;
  font-size: 14px;
  color: rgba(133, 24, 30, 1);
  text-decoration: none;
}

.my-remember-me {
  display: flex;
  align-items: center;
}

.my-forgot-password {
  text-align: right;
}

.my-form-style {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.my-text-label {
  margin-bottom: 5px;
}

.my-input-wrapper {
  position: relative;
  width: 100%;
}

.my-input-pass {
  width: 100%;
  padding-right: 40px;
  /* Adjust padding to prevent text from overlapping with the icon */
}

.my-password-toggle {
  position: absolute;
  top: 50%;
  right: 15px;
  /* Adjust to your preference */
  transform: translateY(-50%);
  cursor: pointer;
}

.my-input-text,
.my-input-pass {
  width: 95%;
  height: 44px;
  padding: 10px;
  margin-bottom: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.my-input-pass {
  width: 95%;
}

.my-log-button {
  padding: 10px;
  background-color: #45a049;
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
}

.btn-disabled {
  background-color: rgb(72, 74, 74);
  cursor: not-allowed;
}

.my-footer-text {
  position: absolute;
  bottom: 0px;
  width: 35%;

  text-align: center;
  padding: 10px;
  /*background-color: #f1f1f1;*/
}

.my-helper-text {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.my-input-error {
  border-color: red;
}

.my-header-language {
  font-family: 'Poppins', sans-serif;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
}
.my-header-language span {
  background-color: #e3e6ea88;
  padding: 10px;
  border-radius: 200px;
  color: #3f040a;
  cursor: pointer;
}

/*MEDIA QUERIES*/
@media (max-width: 1200px) {
  .my-big-title {
    font-size: 2.5rem;
  }

  .my-small-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 785px) {
  .my-right {
    width: 100%;
  }

  .my-left {
    background-position: center;
  }

  .my-overlay img {
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
  }

  .my-content-container {
    top: 30%;
  }

  .my-big-title {
    font-size: 2rem;
  }

  .my-small-title {
    font-size: 1.2rem;
  }

  .my-login-form {
    padding: 10px;
  }
}

@media (max-width: 700px) {
  .my-big-title {
    font-size: 1.5rem;
  }

  .my-small-title {
    font-size: 1rem;
  }
}

@media (max-width: 530px) {
  .my-big-title {
    font-size: 1.2rem;
  }

  .my-small-title {
    font-size: 0.7rem;
  }

  .my-content-container {
    height: 100px;
    padding: 0 8px;
  }
}

.textarea-input {
  width: 95%;
  padding: 10px;
  margin-bottom: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
