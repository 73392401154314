/* body {
  padding-top: 0px;
} */
.login-register-main-container {
  position: absolute;
  height: 100vh;
  display: flex;
  top: 0px;
}
.login-register-picture-container {
  /* width: 62.5vw;
    height: 100%; */
  width: 100%;
  height: 100%;
  /* background-color: blanchedalmond; */
  background: linear-gradient(45deg, rgba(133, 24, 30, 0.9) 0%, rgba(58, 22, 24, 0.9) 100%);

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
  display: flex;
  align-items: end;
  position: relative;
  /* background-position-x: left;  */
}
.login-register-overlay-container {
  width: fit-content;
  /* height: fit-content; */
  /* background-color: blue; */
  width: 62.5vw;
  height: 100%;
  border-radius: 0px 8vh 8vh 0px;
  border-collapse: collapse;
  background-size: cover;
  background-position: 80%;

  /* z-index:  10; */
  background-image: url('../../../../public/assets/login-picture.png');
}
.login-register-text-container {
  width: 100%;
  display: flex;
  gap: 3.333vh;
  flex-direction: column;
  /* height: 24.375vh; */
  /* background-color: aqua; */
  /* border-radius: inherit; */
  padding: 0px 8.33vw 0px 8.33vw;
  margin-bottom: 8vh;
  box-sizing: border-box;
}

.login-register-big-text-container {
  font-family: Inter;
  font-size: clamp(0.5rem, 2.5vw, 56px); /* Base font size */
  font-weight: 500;
  line-height: clamp(1.5rem,5.5vw,95px);
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
  padding: 0px;
  margin: 0px;
  
  /* border-radius: inherit; */
}
.login-register-small-text-container {
  padding: 0px;
  margin: 0px;
  font-family: Inter;
  font-size:  clamp(0.5rem,1.38vw,20px); /* Base font size */

  font-weight: 500;
  line-height: clamp(0.75rem,2.08vw,30px);
  text-align: left;
  color: #d9f6ed;
}
.login-register-content-container {
  width: 37.5vw;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 2.22vw 0px 2.22vw;
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;
}

.login-register-content-container::-webkit-scrollbar {
  /* background-color: rebeccapurple; */
  width: 5px;
}
.login-register-content-container::-webkit-scrollbar-thumb {
  background: #83111d; /* Set color of the scrollbar thumb */
}
.login-register-content-container::-webkit-scrollbar-thumb:hover {
  background: #3f040a; /* Set color of the scrollbar thumb */
}
.login-register-content-container::-webkit-scrollbar-track {
  background: #d3d2d2; /* Set color of the scrollbar track */
}

.login-register-content-form-container {
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.333vh;

  /* gap: 32px; */
}

/* @media (min-width: 600px) {
    .login-register-big-text-container {
      font-size: 3rem;
      line-height: 4.5rem;
    }
  } */
.login-register-stars {
  background-image: url('../../../../public/assets/Starsx4.png');
  width: 8vh;
  aspect-ratio: 1/1;
  background-size: cover;
  position: absolute;
  top: 20vh;
  right: 20vh;
}
.login-register-logo-container {
  height: 4.2vh;
  width: 4.2vh;
  background-color: red;
  border-radius: 100%;
  background-image: url('../../../../public/logo__terra_travel.png');
  background-size: cover;
  min-height: 35px;
  min-width: 35px;
}
.login-register-header-container {
  /* //styleName: Display sm/Semibold; */
  font-family: Inter;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-align: center;
  color: #101828;
}
.login-register-from-fields-container {
  display: flex;
  flex-direction: column;
  gap: 2.083vh;
  width: 25vw;

  /* justify-content: space-between; */
}
.tera-link-container {
  height: 10vh;
  display: flex;
  justify-content: center;
  /* -ms-text-underline-position: unset; */
}
.tera-link-container a {
  text-decoration: none;
  color: #475467;
}
.tera-link-container a:hover {
  color: #000408;
}
.header-language {
  font-family: 'Poppins', sans-serif;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;

}
.header-language span {
  
  background-color: #e3e6ea88;
  padding: 10px;
  border-radius: 200px;
  color:#3f040a;
  cursor: pointer;

}
/* @media (min-width: 1280px) {
  .login-register-big-text-container {
    font-size: 2rem;
    line-height: 3rem;
  }
  .login-register-small-text-container {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1440px) {
  .login-register-big-text-container {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
  .login-register-small-text-container {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
} */


